import { useState } from 'react';
import type { ReactNode } from 'react';
import styled, { DefaultTheme } from 'styled-components';

import { ChevronUpIc, ChevronDownIc } from '@dsch/dd-icons';

import { LinkButton } from 'components/Toolkit/Button/LinkButton';
import { WhiteSpace } from 'components/Toolkit/WhiteSpace/WhiteSpace';

export interface SimpleShowMoreVerticalProps {
  visibleNode: ReactNode;
  hiddenNode: ReactNode;
  showMoreText?: string;
  showLessText?: string;
  className?: string;
  nodeDividerHeight?: keyof DefaultTheme['spacing'];
}

const NodeContainer = styled.div`
  ${({ theme }) => theme.fontSize.B14};
  color: ${({ theme }) => theme.colors.GREY_DARK};
`;

const ShowMoreOrLessLinkButton = styled(LinkButton)`
  margin-top: ${({ theme }) => theme.spacing.M24};
  display: flex;
  align-items: center;
`;

/* Use CSS rather than JS to hide content to ensure that while content is
invisible to the user it is visible in the DOM and therefore search engines.
See https://developers.google.com/search/docs/essentials/spam-policies?visit_id=638114513482068666-3817217458&rd=1#hidden-text-and-links */
const HiddenContentContainer = styled.div<{ showMore: boolean }>`
  display: ${({ showMore }) => (showMore ? 'block' : 'none')};
`;

function SimpleShowMoreVertical(props: SimpleShowMoreVerticalProps) {
  const {
    visibleNode,
    showMoreText = 'Show More',
    showLessText = 'Show Less',
    hiddenNode,
    className,
    nodeDividerHeight = 'S12',
  } = props;
  const [showMore, setShowMore] = useState(false);

  return (
    <div className={className}>
      <NodeContainer>{visibleNode}</NodeContainer>
      <HiddenContentContainer showMore={showMore}>
        <WhiteSpace spacing={nodeDividerHeight} />
        <NodeContainer>{hiddenNode}</NodeContainer>
      </HiddenContentContainer>
      <ShowMoreOrLessLinkButton
        ofType="SECONDARY"
        fontSize="B14"
        onClick={() => setShowMore(!showMore)}
        underline
      >
        {showMore ? (
          <>
            {showLessText}&nbsp;
            <ChevronUpIc width={16} height={16} />
          </>
        ) : (
          <>
            {showMoreText}&nbsp;
            <ChevronDownIc width={16} height={16} />
          </>
        )}
      </ShowMoreOrLessLinkButton>
    </div>
  );
}

export { SimpleShowMoreVertical };
