import getConfig from 'next/config';

const {
  publicRuntimeConfig: { GOOGLE_RECAPTCHA, INTEGRATION_TESTING },
} = getConfig();

function getRecaptchaToken(
  action: string,
  key: string,
  integration: boolean,
): Promise<string> {
  if (integration) {
    return Promise.resolve('');
  } else {
    return new Promise((resolve, reject) => {
      try {
        (window as any).grecaptcha?.enterprise.ready(() => {
          return (window as any).grecaptcha.enterprise
            .execute(key, {
              action,
            })
            .then((token: string) => {
              resolve(token);
            });
        });
      } catch (error) {
        reject(error);
      }
    });
  }
}

async function generateRecaptchaToken(pageName: string) {
  const token = await getRecaptchaToken(
    pageName,
    GOOGLE_RECAPTCHA,
    INTEGRATION_TESTING,
  );
  return token;
}

export { getRecaptchaToken, generateRecaptchaToken };
