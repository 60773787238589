import styled from 'styled-components';

import { media } from 'helpers/breakpoints';
import { Link } from 'components/Toolkit/Button/Link';
import { SimpleShowMoreVertical } from 'components/Toolkit/SimpleShowMore/SimpleShowMoreVertical';

type LinkType = {
  text: string;
  url: string;
  make?: string;
  model?: string;
  year?: string;
  county?: string;
  fuelType?: string;
  sortKey?: number;
};

interface InternalLinksProps {
  className?: string;
  title?: string;
  links: LinkType[];
  limit?: number;
}

const InternalLinksTitle = styled.h2`
  ${({ theme }) => theme.fontSize.L18};
  color: ${({ theme }) => theme.colors.GREY_DARKER};
  margin-bottom: ${({ theme }) => theme.spacing.M24};
`;

const InternalLinksContainer = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ theme }) => theme.spacing.S12};
  ${({ theme }) => theme.fontSize.M14};
  grid-row-gap: ${({ theme }) => theme.spacing.S12};

  ${media.medium} {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const InternalLink = styled(Link)`
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

function InternalLinksGroup({ links }: { links: LinkType[] }) {
  return (
    <InternalLinksContainer>
      {links.map((link) => (
        <li key={link.url}>
          <InternalLink
            href={link.url}
            ofType="TERTIARY"
            colorVariant="GREY_DARK"
          >
            {link.text}
          </InternalLink>
        </li>
      ))}
    </InternalLinksContainer>
  );
}

function InternalLinks({
  title,
  links,
  className,
  limit = 60,
}: InternalLinksProps) {
  return (
    <div className={className} data-testid="internal-link-list">
      {title && <InternalLinksTitle>{title}</InternalLinksTitle>}
      {links.length > limit ? (
        <SimpleShowMoreVertical
          visibleNode={<InternalLinksGroup links={links.slice(0, limit)} />}
          hiddenNode={<InternalLinksGroup links={links.slice(limit, -1)} />}
          nodeDividerHeight="S12"
        />
      ) : (
        <InternalLinksGroup links={links} />
      )}
    </div>
  );
}

export { InternalLinks };
